// https://docs.getunleash.io/reference/sdks/javascript-browser
import { UnleashClient } from 'unleash-proxy-client';
import config from '../config';

const unleashConfig = {
  url: 'https://us.app.unleash-hosted.com/usff0003/api/frontend',
  clientKey: config.UNLEASH_CLIENT_KEY,
  appName: 'dashboard-v2',
  refreshInterval: 0 // never refresh without reload of page
};

let client;

/**
 * All keys in Unleash would be the values of this object only.
 */
export const UnleashKeys = {
  // developer override for all features
  GodMode: 'god-mode',
  // add other feature keys here
  CashDrop: 'enable-cash-drop',
  SalesBySeller: 'enable-sales-by-seller',
  ImportOrders: 'enable-import-order',
  EditRaffle: 'edit-raffle',
  QuickbooksInvoices: 'quickbook-invoices',
  TopSellersReport: 'top-seller-report',
  ViewReports: 'view-reports',
  ManageDomains: 'manage-domains',
  Admin: 'admin',
  StartARafflebox: 'start-a-rafflebox',
  ViewRaffles: 'view-raffles',
  EditRaffleTicker: 'edit-raffle-ticker',
  CreateCashSales: 'create-cash-sales',
  MessageCenter: 'message-center',
  EditMessageCenter: 'edit-message-center',
  OrdersV2Beta: 'orders-v-2',
  EditRaffleTags: 'edit-raffle-tags',
  ViewGoldrushRaffles: 'view-goldrush-raffles',
  ViewSystemInfo: 'view-system-info',
  CreateRaffle: 'create-raffle',
  CopyRaffle: 'copy-raffle',
  ChangeOrganization: 'change-organization',
  DeactivateRaffle: 'deactivate-raffle',
  EditBilling: 'edit-billing',
  ViewCustomers: 'view-customers',
  ViewDevices: 'view-devices',
  ViewOrders: 'view-orders',
  DownloadTickets: 'download-tickets',
  ActivateRaffle: 'activate-raffle',
  ViewGoldrushV2: 'view-goldrush-v-2',
  SetTicketLimit: 'set-ticket-limit',
  CreateOrganization: 'create-organization',
  ActivateAllEventMembers: 'activate-all-event-members',
  DeleteRaffle: 'delete-raffle',
  EditCustomer: 'edit-customer',
  EditPayoutSchedule: 'edit-payout-schedule',
  PrintTickets: 'print-tickets',
  VoidOrdersV2: 'void-orders-v-2',
  EditRaffleEventMembers: 'edit-raffle-event-members',
  EditRaffleAdvanced: 'edit-raffle-advanced',
  EditTicketPackages: 'edit-ticket-packages',
  EditRaffleFees: 'edit-raffle-fees',
  EditRaffleFeesTab: 'edit-raffle-fees-tab',
  EditRaffleEmail: 'edit-raffle-email',
  EditRaffleInVenue: 'edit-raffle-in-venue',
  EditRaffleSalesRep: 'edit-raffle-sales-rep',
  EmailOrder: 'email-order',
  EndRaffle: 'end-raffle',
  EditRaffleProcessingFees: 'edit-raffle-processing-fees',
  ImportEventMembers: 'import-event-members',
  GoldrushSales: 'goldrush-sales',
  InVenueImprovements: 'in-venue-improvements',
  PrintOrder: 'print-order',
  AdminInvoices: 'admin-invoices',
  AdminOrganizations: 'admin-organizations',
  AdminOrders: 'admin-orders',
  AdminUsers: 'admin-users',
  AdminRaffles: 'admin-raffles',
  AdminDevices: 'admin-devices',
  AdminEditOrganization: 'admin-edit-organization',
  ViewAccount: 'view-account',
  AccountStripe: 'account-stripe',
  AccountPayouts: 'account-payouts',
  AccountInvoices: 'account-invoices',
  AccountValidateInvoice: 'account-validate-invoice',
  AccountAuthorizeInvoice: 'account-authorize-invoice',
  AccountPayInvoice: 'account-pay-invoice',
  AccountDeleteInvoice: 'account-delete-invoice',
  AccountEditInvoice: 'account-edit-invoice',
  AccountRaffleboxPayouts: 'account-rafflebox-payouts',
  ViewUsers: 'view-users',
  ViewTickets: 'view-tickets',
  SendTestOrderEmail: 'send-test-order-email',
  ViewDashboard: 'view-dashboard',
  EditRaffleRBAdminSettings: 'edit-raffle-rb-admin-settings',
  RequestRaffleActivation: 'request-raffle-activation',
  PrizeDrawsNewPrizeFlow: 'prize-draws-new-prize-flow',
  PrizeDrawsPrizeControls: 'prize-draws-prize-controls',
  ViewStations: 'view-stations',
  EditUserDetails: 'edit-user-details',
  EditRaffleRestricted: 'edit-raffle-restricted',
  GoldrushStationFilter: 'goldrush-station-filter',
  EventMemberV2: 'event-member-v-2',
  ImportCustomers: 'import-customers',
  ExportCustomers: 'export-customers',
  InboundCheckout: 'inbound-checkout',
  ViewDonations: 'view-donations',
  ProcessBatchOrders: 'process-batch-orders',
  CreateOrderV2: 'create-order-v2',
  EditOrderV2: 'edit-order-v-2',
  BulkPrizes: 'bulk-prizes',
  EditUserForMNPRoles: 'edit-user-for-mnp-roles',
  RaffleSalesNew: 'raffle-sales-new',
  SalesSummaryV2: 'sales-summary-v-2',
  TicketPackageSalesReportV2: 'ticket-package-sales-report-v-2',
  DeviceSalesReportV2: 'device-sales-report-v-2',
  Mongo: 'mongo',
  SupportSuperAdmin: 'SupportSuperAdmin',
  ViewPlayers: 'view-players',
  StripeFutureRequirements: 'stripe-future-requirements',
  StripeRequirementDetails: 'stripe-requirement-details',
  StripeErrorDetails: 'stripe-requirement-errors',
  CanAssignRbRoles: 'can-assign-rb-roles',
  CanAssignDeveloperRole: 'can-assign-developer-role',
  DecommissionMetabase: 'decommission-metabase',
  ContextBody: 'context-body',
  ViewImports: 'view-imports',
  ViewRaffleCards: 'view-raffle-cards',
  ViewFileImporter: 'view-file-importer',
  ShowStripeBanner: 'show-stripe-banner',
  ShowCustomerImporter: 'show-customer-importer'
};

const refreshClient = async () => {
  await client.stop();
  await client.start();
  console.log('[Unleash] Client restarted and flags fetched with updated context');
};

const formatContext = (user) => {
  if (!user) {
    return {};
  }
  const roles = user.roles.reduce((map, role) => {
    const key = role.replace(/\s+/g, '');
    map[key] = 'true';
    return map;
  }, {});

  return {
    userId: user.id,
    organizationId: user.organizationUuid,
    email: user.email,
    ...roles
  };
};

export const initializeUnleash = async (user) => {
  console.log('[Unleash] Initializing Unleash client');
  client = new UnleashClient(unleashConfig);

  // Wait for the client to be fully ready
  await client.start();

  // Ensure the client is ready by waiting for the client.isReady() event
  await new Promise((resolve) => {
    if (client.isReady()) {
      resolve();
    } else {
      client.on('ready', () => resolve());
    }
  });

  console.log('[Unleash] Client started and ready');
};

export const updateUnleashContext = async (user) => {
  if (!client) {
    // eslint-disable-next-line
    console.warn('[Unleash] Client is not initialized');
    return;
  }

  const context = formatContext(user);

  client.updateContext(context);
  console.log('[Unleash] Updated context with user');
  await refreshClient();
};

export const clearUnleashContext = async () => {
  if (!client) {
    // eslint-disable-next-line
    console.warn('[Unleash] Client is not initialized');
    return;
  }

  client.updateContext({});
  console.log('[Unleash] Cleared context');
  await refreshClient();
};

export const unleashFeatureEnabled = async (key) => {
  if (!client) {
    // eslint-disable-next-line
    console.warn('[Unleash] Client is not initialized');
    return false;
  }

  // Ensure client is ready before checking flags
  if (!client.isReady()) {
    console.log('[Unleash] Waiting for client to be ready');
    await new Promise((resolve) => client.on('ready', () => resolve()));
  }

  const godModeEnabled = client.isEnabled(UnleashKeys.GodMode);

  if (godModeEnabled) {
    return true;
  }

  return client.isEnabled(key);
};
