<template>
  <div class="max-h-1/2">
    <div v-if="loading" class="flex justify-center items-center h-64">
      <LoadingSpinner size="lg" variant="default" />
    </div>
    <div v-else>
      <h4 class="my-3 ml-3">{{ title }}</h4>
      <ApexChart width="100%" height="550px" type="bar" :options="options" :series="series"></ApexChart>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import { format, max } from 'date-fns';

export default {
  components: {
    LoadingSpinner
  },
  props: {
    title: {
      type: String,
      required: true
    },
    fetchData: {
      type: Function,
      required: true
    },
    chartId: {
      type: String,
      required: true
    },
    csvFilename: {
      type: String,
      required: true
    },
    series: {
      type: Array,
      default: () => []
    },
    headerCategory: {
      type: String
    }
  },
  data() {
    return {
      options: {},
      loading: true,
      formattedFilename: ''
    };
  },
  async mounted() {
    await this.fetchData();
    this.formattedFilename = `${this.csvFilename} - ${format(new Date(), 'yyyy-MM-dd-ppp')}`;

    this.options = {
      chart: {
        id: this.chartId,
        redrawOnParentResize: true,
        toolbar: {
          tools: {
            download: true
          },
          export: {
            csv: {
              filename: this.formattedFilename,
              headerCategory: this.headerCategory,
              valueFormatter(y) {
                return '$' + y;
              }
            }
          },
          offsetY: -30
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return '$' + value;
          }
        },
        max: max(this.series[0].data.map((item) => item.y)) * 1.1,
        min: 0
      },
      xaxis: {
        title: { text: undefined },
        type: 'category'
      },
      colors: ['#88BF4D'],
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        formatter: function (value) {
          if (value >= 1000000) {
            return `$${(value / 1000000).toFixed(1)}M`;
          } else if (value >= 10000) {
            return `$${Math.round(value / 1000)}K`;
          } else if (value >= 1000) {
            return `$${(value / 1000).toFixed(1)}K`;
          }
          return '$' + value;
        },
        style: {
          fontfamily: 'Futura PT',
          fontWeight: 'bold',
          fontSize: '14px',
          colors: ['#58627C']
        },
        offsetY: -30
      }
    };

    this.loading = false;
  }
};
</script>
